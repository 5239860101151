<template>
  <div>
    <!-- Back button  if u want to use this breadcrumbs just remove the hidden class -->
    <div class="heading-sec">
      <div class="row">
        <div class="col-md-4 column">
          <div class="heading-profile">
            <div class="font-24 mt-10 ml-4">DISCOVER MORE</div>
          </div>
        </div>
      </div>
    </div>
    <!-- Top Bar Chart -->
    <div class="justify-center" align="center">
      <!-- Top Bar Chart -->
      <v-card class="mt-2 mx-4">
        <div class="panel-content">
          <div class="row">
            <div class="col-md-12">
              <div class="widget">
                <div class="form-elements-sec">
                  <div class="form-body">
                    <v-form
                      name="details"
                      class="formPart text-left"
                      @submit.prevent="submitForm(id)"
                    >
                      <div>
                        <div class="form-group col">
                          <label for="link"
                            >Palettier SKU Codes:
                            <v-btn @click="addSku" dense>Add</v-btn>
                          </label>

                          <div class="poppins-medium font-16 pl-4 d-block">
                            <v-row no-gutters>
                              <v-col
                                cols="6"
                                v-for="(item, index) in details.query"
                                :key="index"
                                class=""
                              >
                                <v-text-field
                                  v-model="item.sku"
                                  width="200px"
                                  outlined
                                  dense
                                  class="mt-6 mr-4 px-2"
                                  >{{ item.sku }}</v-text-field
                                >
                              </v-col>

                              <!-- <v-col cols="4">
                              <img
                                @click="confirm(image, 'image')"
                                src="@/assets/Images/remove.svg"
                                alt="logo"
                                class="pl-15"
                              />
                            </v-col> -->
                            </v-row>
                          </div>
                        </div>
                      </div>
                      <!-- button -->
                      <div class="btnPart text-center my-4">
                        <div class="mt-2 mb-2" v-if="loader" align="center">
                          <v-progress-circular indeterminate color="primary"></v-progress-circular>
                        </div>
                        <button :disabled="loader" type="submit" class="btn btn-success">
                          Save
                        </button>
                        <p class="typo__p" v-if="submitStatus === 'OK'">
                          Thanks for your submission!
                        </p>
                        <p class="typo__p" v-if="submitStatus === 'ERROR'">
                          Please fill the form correctly.
                        </p>
                        <v-btn color="error" class="mx-3" v-on:click="cancelSku()"> Cancel </v-btn>
                      </div>
                    </v-form>
                    <div
                      class="
                        table-body-contents
                        text-center
                        m-3
                        font-size-md font-weight-bold
                        text-muted
                      "
                      v-if="details.query && details.query.length === 0"
                    >
                      No Data Found
                    </div>
                  </div>
                </div>
                <!-- <ConfirmDlg ref="confirm" /> -->
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import homeService from '../../services/homeService';
import imageUploadService from '../../services/imageUploadService';

export default {
  data() {
    return {
      details: {
        query: [],
      },
      submitStatus: null,
      loader: false,
      id: '',
    };
  },

  methods: {
    // this method use for validation error

    // this is use for save button
    async submitForm(details) {
      this.submitStatus = 'OK';
      let query = [];
      this.details.query.map((sku) => {
        let data = {};
        console.log(sku, 'sku-here');
        if (sku.sku && sku.sku != '') {
          data.sku = sku.sku.trim();
          query.push(data);
        }
      });
      this.details.query = query;
      this.details.user = JSON.parse(localStorage.getItem('selectedVendor'));
      let result;
      if (this.id && this.id !== '') {
        result = await homeService.updateCuratedEditData(this.id, this.details);
      } else {
        result = await homeService.createCuratededit(this.details);
      }

      if (result.status === 200) {
        this.$store.commit('setSnackbar', {
          content: 'Submitted Successfully',
          icon: 'mdi-check-circle',
          color: 'success ',
          isVisible: true,
        });
        // this.$router.go();
      } else {
        this.$store.commit('setSnackbar', {
          content: 'Something went wrong !',
          icon: 'mdi-alert-circle',
          color: 'error ',
          isVisible: true,
        });
      }
      // }
    },

    async addSku() {
      const data = {};
      data.sku = '';
      this.details.query.push(data);
    },
    async getAllcuratedEdits() {
      const data = {};
      data.page = 1;
      data.user = JSON.parse(localStorage.getItem('selectedVendor'));

      const result = await homeService.getAllCuratedEdits(data);
      if (result.status == 200) {
        if (result && result.data && result.data.data.length) {
          this.details.query = result.data.data[0].query;
          this.id = result.data.data[0]._id;
          console.log(this.id, result.data.data[0].query);
        }
      }
    },
  },
  created() {
    this.getAllcuratedEdits();
  },
};
</script>
<style lang="scss" scoped>
.validation {
  color: red;
}
.preview-image {
  width: 300px;
  height: 350px;
}
</style>
